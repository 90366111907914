import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { css } from "@emotion/core"
import { Link } from "gatsby"
const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <div
      className='my-5'
      css={css`
        max-width: 500px;
        min-height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: center;

        a {
          display: block;
        }
      `}
    >
      <b>404</b>
      <h1 className='mb-4'>
        We could not find the page you were looking for..
      </h1>
      <p className='mb-2'>Here are some links that you may be interested in.</p>
      <Link to='/'>Homepage</Link>
      <Link to='/products'>Our Products</Link>
      <Link to='/contact-us'>Contact us</Link>
    </div>
  </Layout>
)

export default NotFoundPage
